import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import MainLayout from "./layouts/MainLayout/MainLayout";
import DashboardLayout from "./layouts/DashboardLayout/index";

export const routes = [
  {
    exact: true,
    path: "/",
    // layout: MainLayout,
    component: lazy(() => import("./views/pages/Auth/Login/index")),
  },
  {
    exact: true,
    path: "/forgotpassword",
    // layout: MainLayout,
    component: lazy(() =>
      import("./views/pages/Auth/Login/ForgotPassword/forgotPassword")
    ),
  },
  {
    exact: true,
    path: "/otpverification",
    // layout: MainLayout,
    component: lazy(() =>
      import("./views/pages/Otp-Verification/OTPVerification")
    ),
  },
  {
    exact: true,
    path: "/resetpassword",
    // layout: MainLayout,
    component: lazy(() => import("./views/pages/Reset-Password/resetpassword")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/dashboard")),
  },
  {
    exact: true,
    path: "/project-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/ProjectManagement/ProjectManagement")
    ),
  },
  {
    exact: true,
    path: "/project-management/view/:id",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/ProjectManagement/ViewProject")
    ),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/UserManagement/UserManagement")
    ),
  },
  {
    exact: true,
    path: "/user-management/view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    path: "/subscription-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SubscriptionManagement/SubscriptionManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/transaction-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/TransactionManagement/TransactionManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/profile",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/Profile/AdminProfile")
    ),
  },
  {
    exact: true,
    path: "/support",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/SupportTicket/SupportTicket")
    ),
  },
  {
    exact: true,
    path: "/widget",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/WidgetManagement/WidgetManagement")
    ),
  },
  {
    exact: true,
    path: "/billings",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/Billings&Invoices/Billings&Invoices")
    ),
  },
  {
    exact: true,
    path: "/analytics-reports",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/Analytics & Reports/Analytics&Reports")
    ),
  },
  {
    exact: true,
    path: "/create-analytics",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/Analytics & Reports/CreateReport")
    ),
  },
  {
    exact: true,
    path: "/sub-admin",
    layout: DashboardLayout,
    component: lazy(() => import("./views/pages/Dashboard/Sub Admin/index")),
  },
  {
    exact: true,
    path: "/content-management-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/index")
    ),
  },
  {
    exact: true,
    path: "/content-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Term&Condition/ViewTerm"
      )
    ),
  },
  {
    exact: true,
    path: "/view-banner-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/BannerManagement/BannerManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-content-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/Term&Condition/EditTerm"
      )
    ),
  },
  {
    exact: true,
    path: "/add-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQ/AddFaq")
    ),
  },
  {
    exact: true,
    path: "/edit-faq",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQ/EditFaq")
    ),
  },
  {
    exact: true,
    path: "/add-banner",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/BannerManagement/AddBanner"
      )
    ),
  },
  {
    exact: true,
    path: "/view-userlist",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/SubscriptionManagement/ViewSubscriptionList"
      )
    ),
  },
  {
    exact: true,
    path: "/edit-banner",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "./views/pages/Dashboard/StaticContentManagement/BannerManagement/EditBanner"
      )
    ),
  },
  {
    exact: true,
    path: "/faq-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/FAQ/Faqlist")
    ),
  },
  {
    exact: true,
    path: "/team-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/Team/Teamlist")
    ),
  },
  {
    exact: true,
    path: "/add-subscription",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/SubscriptionManagement/AddSubscription")
    ),
  },
  {
    exact: true,
    path: "/view-subscription",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/SubscriptionManagement/ViewDetails")
    ),
  },
  {
    exact: true,
    path: "/view-team",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/Team/ViewTeam")
    ),
  },
  {
    exact: true,
    path: "/edit-team",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/StaticContentManagement/Team/EditTeam")
    ),
  },

  {
    exact: true,
    path: "/sub-admin/View",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/Sub Admin/ViewSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/sub-admin/Edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/Sub Admin/EditSubAdmin")
    ),
  },
  {
    exact: true,
    path: "/add-sub-admin",
    layout: DashboardLayout,
    component: lazy(() =>
      import("./views/pages/Dashboard/Sub Admin/addSubAdmin")
    ),
  },
];
